import { LocationProviderContextProps } from "@RHCommerceDev/custom-providers/LocationProvider";
import { getClientOrigin } from "@RHCommerceDev/utils/getClientOrigin";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";
import { getLocaleFromPath } from "@RHCommerceDev/utils/intlUtils";
import {
  getBrandFromHostname,
  getAemRootPath,
  isRootPath
} from "@RHCommerceDev/utils/aemPathUtils";
import { getReqContext } from "@RHCommerceDev/utils/reqContext";
import {
  globalQueryVariables,
  queryGetAemModelGlobal,
  globalAemModel
} from "@RHCommerceDev/graphql-client-queries-get-aem-model";
import { getSelectedLocalization } from "utils/getCountryFromUrl";
import { fetchParams } from "@RHCommerceDev/hooks/useFetchParams/useFetchParams";

//Add Global AEM models to this object, they will be processed and added to the main GraphQL query.
const globalModelPaths = {
  footerLinks: "/admin/footer-links",
  hamburgerNav: "/admin/hamburger-nav",
  emailSignupContent: "/admin/marketing-email-popup",
  cookieModal: "/admin/cookie-modal"
};
export const globalModels = globalModelPaths;

export const processModelPath = (path, Brand = null) => {
  const hostName = processEnvServer
    ? getClientOrigin()
    : window?.location?.origin;
  const brand = Brand
    ? Brand
    : (getBrandFromHostname(
        hostName
      ).toLowerCase() as LocationProviderContextProps["brand"]) || "rh";
  const { locale } = getLocaleFromPath(path.replace(".model.json", "")) || {
    locale: "/us/en/"
  };
  const aemPath = isRootPath(path.replace(".model.json", ""))
    ? getAemRootPath(brand, locale)
    : (path.includes("/" + brand + "/") &&
      !path.endsWith("/" + brand + "/") &&
      !path.endsWith("/rh/")
        ? path
        : `/${brand}/${path.includes(locale) ? "" : locale}${path}`
      )
        .replace("content/", "")
        .replace(".jsp", "")
        .replace("//", "/")
        .replace(".model.json", "")
        .replace(".html", "")
        .toLowerCase();
  //getmodel needs leading slashes
  const modelPath = (
    Array.from(aemPath)[0] === "/" ? aemPath : "/" + aemPath
  ).replace(/\/\/+/g, "/");
  return modelPath;
};

export const aemFetchHook = ({
  path = "/rh/us/en/home",
  isJSPPage = false,
  isPage = true,
  graphqlClient,
  skip = false
}) => {
  const modelPath =
    isJSPPage || !isPage ? processResourcePath(path) : processModelPath(path);

  let modelPaths = parsePaths(globalModelPaths);

  if (modelPath?.includes("products")) {
    let url: URL | { pathname: string; search: string };

    if (processEnvServer) {
      const req = getReqContext();
      const urlString = req?.path || "";
      const [pathname, search = ""] = urlString.split("?");
      url = { pathname, search };
    } else {
      url = new URL(window?.location?.href);
    }

    const searchParams =
      url instanceof URL ? url.searchParams : new URLSearchParams(url.search);

    const categoryId =
      searchParams.get("categoryId") ??
      fetchParams(url?.pathname, "products.jsp")?.at(0);

    if (categoryId) {
      modelPaths = parsePaths({
        ...globalModelPaths,
        pgHeader: `/category/${categoryId}/header`,
        pgFooter: `/category/${categoryId}/footer`
      });
    }
  }

  return globalAemModel({
    modelPath: modelPath.replace(/\/\/+/g, "/"),
    globalPaths: modelPaths,
    graphqlClient,
    skip
  });
};

export async function fetchModel({
  //todo: aem 404 page here
  path = "/rh/us/en/home",
  isAemPage = false,
  graphqlClient
}): Promise<any> {
  const modelPath = processModelPath(path);
  const result = await graphqlClient
    .query({
      query: queryGetAemModelGlobal,
      variables: globalQueryVariables(modelPath)
    })
    .catch(err => {
      console.warn(err);
    });
  try {
    if (
      result &&
      result.data &&
      result.data.getAemModel &&
      result.data.getAemModel.model &&
      result.data.getAemModel.model !== ""
    ) {
      const mappedValue = JSON.parse(result.data.getAemModel.model);
      return mappedValue;
    }
    return result ? result.data : {};
  } catch (err) {
    console.error(err);
    return {};
  }
}

export const processResourcePath = (path, Brand = null) => {
  const hostName = processEnvServer
    ? getClientOrigin()
    : window?.location?.origin;
  const req = processEnvServer ? getReqContext() : false;
  const { location } = window;
  const { locale } = getLocaleFromPath(
    (req ? (req as any).path : location.pathname) || path
  ) || { locale: "/us/en/" };
  const folderPrefix =
    path.indexOf(locale) > -1
      ? ""
      : locale + (path.indexOf("admin") > -1 ? "" : "admin");
  const brand = Brand
    ? Brand
    : (getBrandFromHostname(
        hostName
      ).toLowerCase() as LocationProviderContextProps["brand"]) || "rh";
  const aemPath = `${
    path.includes("/" + brand + "/") ? "" : brand
  }${folderPrefix}/${path}`
    .replace("content/", "")
    .replace(".jsp", "")
    .replace("//", "/")
    .replace(".model.json", "")
    .replace(".html", "")
    .toLowerCase();
  //getmodel needs leading slashes
  const modelPath = Array.from(aemPath)[0] === "/" ? aemPath : "/" + aemPath;
  return modelPath;
};

export const parsePaths = (paths: Record<string, string>) => {
  const locale = getSelectedLocalization() || "/us/en";

  return Object.fromEntries(
    Object.entries(paths).map(([key, value]) => [
      key,
      processResourcePath(`${locale}${value}`)
    ])
  );
};

export async function fetchResource({ path, graphqlClient }) {
  const modelPath = processResourcePath(path);
  const globalPaths = {
    footerLinks: processResourcePath("/admin/footer-links"),
    hamburgerNav: processResourcePath("/admin/hamburger-nav")
  };
  const result = await graphqlClient
    .query({
      query: queryGetAemModelGlobal,
      variables: globalQueryVariables(modelPath)
    })
    .catch(err => {
      console.warn(err);
    });
  try {
    if (
      result &&
      result.data &&
      result.data.getAemModel &&
      result.data.getAemModel.model &&
      result.data.getAemModel.model !== ""
    ) {
      const mappedValue = JSON.parse(result.data.getAemModel.model);
      return mappedValue;
    }
    return result ? result.data : {};
  } catch (err) {
    console.error(err);
    return {};
  }
}
